import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby-link";

export default function ClassCard({ title, text, url, photo }) {
  return (
    <div
      style={{
        border: "1px solid #E0E0E0",
        cursor: "pointer",
        borderRadius: "8px",
        opacity: 1,
      }}
      className='img-zoom'
      onClick={() => navigate(url)}
    >
      <img
        style={{
          cursor: "pointer",
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
          opacity: 1,
        }}
        src={`${photo}`}
      />
      <div className="m-10">
        <h3>{title}</h3>
        <p className="mt-3">{text}</p>
      </div>
    </div>
  );
}

ClassCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  photo: PropTypes.string,
  url: PropTypes.any,
};
